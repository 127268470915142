import React from "react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useWebSocket } from "../contexts/WebSocketContext";

export const NFLNewsFooter = withLDConsumer()(({ flags }) => {
  const { latestNewsData: latestNews = [], error, connectionStatus } = useWebSocket();

  // Ensure we have an array to work with
  const safeLatestNews = Array.isArray(latestNews) ? latestNews : [];
  
  // Take only the last 5 items and reverse them
  const displayNews = safeLatestNews.slice(-5).reverse();

  // Handle different states
  if (connectionStatus?.latestNews === 'CONNECTING') {
    return <div>Connecting to news feed...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {displayNews.length > 0 ? (
        displayNews.map((nflnewsItem, index) => (
          <div key={nflnewsItem.id || index} style={{ marginLeft: "50px", flex: "0 1 auto" }}>
            <a
              href={nflnewsItem.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <p style={{ margin: 0 }}>
                {nflnewsItem?.image && (
                  <img
                    src={nflnewsItem.image}
                    alt={nflnewsItem.title || 'News image'}
                    style={{
                      width: "auto",
                      height: "40px",
                      marginRight: "10px",
                    }}
                  />
                )}
                {nflnewsItem?.title || 'No title available'}
              </p>
            </a>
          </div>
        ))
      ) : (
        <p>Loading news...</p>
      )}
    </div>
  );
});

export default NFLNewsFooter;