import { callExternalApi } from "./external-api.service";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getAllRosters = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/rosters/allrosters`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    return response.data;
  } catch (error) {
    console.error('Error in getAllRosters:', error);
    return { rosters: null, error: error.message || 'Unknown error occurred' };
  }
};

export const getRoster = async (accessToken, managerEmail, week) => {
  const encodedEmail = encodeURIComponent(managerEmail);
  const encodedWeek = encodeURIComponent(week);
  const url = `${apiServerUrl}/api/rosters/roster/${encodedEmail}/${encodedWeek}`;
  
  console.log('getRoster called with:', { managerEmail, week });
  console.log('Constructed URL:', url);
  
  const config = {
    url,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getRoster:', error);
    return { roster: null, error: error.message || 'Unknown error occurred' };
  }
};

export const getTeamLimits = async (accessToken, managerEmail, week) => {
  const encodedEmail = encodeURIComponent(managerEmail);
  const encodedWeek = encodeURIComponent(week);
  const config = {
    url: `${apiServerUrl}/api/rosters/team-limits/${encodedEmail}?week=${encodedWeek}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await callExternalApi({ config });
    // Maintain the same response structure as before while using callExternalApi
    console.log('API Response for Team Limits:', response);
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error fetching team limits:", error);
    return { data: null, error: error.message || 'Unknown error occurred' };
  }
};