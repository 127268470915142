import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useTheme } from "../contexts/ThemeContext";
import { useWebSocket } from "../contexts/WebSocketContext";

function getVariation(nflNewsComponent, nflNews, fontSize) {
  if (nflNewsComponent === "enabled") {
    return nflNews.length > 0 ? (
      nflNews.map((nflnewsItem) => (
        <tr key={nflnewsItem.id}>
          <td>
            <a
              href={nflnewsItem.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                fontSize: `${fontSize}px`,
                color: "inherit",
              }}
            >
              {nflnewsItem.image && (
                <img
                  src={nflnewsItem.image}
                  alt={nflnewsItem.title}
                  style={{
                    width: "auto",
                    height: "40px",
                  }}
                />
              )}
            </a>
          </td>
          <td className="text-start">
            <a
              href={nflnewsItem.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                fontSize: `${fontSize}px`,
                color: "inherit",
              }}
            >
              {nflnewsItem.title}
            </a>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td>Loading news...</td>
      </tr>
    );
  } else if (nflNewsComponent === "disabled") {
    return (
      <tr>
        <td>
          <h3>NFL News is not enabled</h3>
        </td>
      </tr>
    );
  } else if (nflNewsComponent === "restricted") {
    return (
      <tr>
        <td>
          NFL News is only available to TUFF Premium Members. Upgrade your TUFF
          subscription to Premium to access NFL News.
        </td>
      </tr>
    );
  }
}

export const NFLNewsPage = withLDConsumer()(({ flags, fontSize }) => {
  const { theme } = useTheme();
  const { newsData, sendMessage, connectionStatus, error } = useWebSocket();
  const [localError, setLocalError] = useState(null);

  useEffect(() => {
    // Request initial news data when component mounts
    if (connectionStatus.news === 'OPEN' && flags.nflNewsComponent === "enabled") {
      try {
        sendMessage('news', {
          action: 'getNFLNews',
          type: 'fetchAll'
        }).catch(err => {
          setLocalError('Failed to fetch news data: ' + err.message);
        });
      } catch (err) {
        setLocalError('Failed to send message: ' + err.message);
      }
    }
  }, [connectionStatus.news, flags.nflNewsComponent, sendMessage]);

  const variation = getVariation(flags.nflNewsComponent, newsData || [], fontSize);

  // Handle connection status messages
  const renderConnectionStatus = () => {
    if (localError || error) {
      return <div className="text-danger">Error: {localError || error}</div>;
    }
    if (connectionStatus.news === 'CONNECTING') {
      return <div className="text-warning">Connecting to news feed...</div>;
    } else if (connectionStatus.news === 'CLOSED') {
      return <div className="text-danger">Connection lost. Attempting to reconnect...</div>;
    }
    return null;
  };

  return (
    <div>
      <h4>NFL News</h4>
      {renderConnectionStatus()}
      <Table hover size="sm" variant={theme}>
        <tbody>{variation}</tbody>
      </Table>
    </div>
  );
});

export default NFLNewsPage;