import React, { createContext, useContext, useState, useRef, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const WebSocketContext = createContext({
    // Provide default values
    newsData: [],
    latestNewsData: [],
    standingsData: [],
    teamPointsData: [],
    error: null,
    connectionStatus: {
        news: 'CONNECTING',
        latestNews: 'CONNECTING'
    },
    sendMessage: () => Promise.reject(new Error('WebSocket not initialized'))
});

export const WebSocketProvider = withLDConsumer()(({ flags, children }) => {
    // Initialize with empty arrays and default values
    const [newsData, setNewsData] = useState([]);
    const [latestNewsData, setLatestNewsData] = useState([]);
    const [standingsData, setStandingsData] = useState([]);
    const [teamPointsData, setTeamPointsData] = useState([]);
    const [error, setError] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState({
        news: 'CONNECTING',
        latestNews: 'CONNECTING'
    });
    
    // WebSocket refs
    const newsWebSocketRef = useRef(null);
    const latestNewsWebSocketRef = useRef(null);
    const reconnectTimeoutRef = useRef({
        news: null,
        latestNews: null
    });
    
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const logDebug = useCallback(
        (message, ...args) => {
            if (flags?.frontendConsoleLogging) {
                console.log(message, ...args);
            }
        },
        [flags?.frontendConsoleLogging]
    );

    const sendMessage = useCallback((type, message) => {
        return new Promise((resolve, reject) => {
            const wsRef = type === 'news' ? newsWebSocketRef.current : latestNewsWebSocketRef.current;
            
            if (!wsRef || wsRef.readyState !== WebSocket.OPEN) {
                reject(new Error(`${type} WebSocket is not connected`));
                return;
            }

            try {
                wsRef.send(JSON.stringify(message));
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, []);

    const handleMessage = useCallback((type, event) => {
        if (!event?.data) {
            console.error(`Invalid message received (${type})`);
            return;
        }

        console.log(`RAW WebSocket message received (${type}):`, event.data);
        try {
            const data = JSON.parse(event.data);
            console.log(`PARSED WebSocket message (${type}):`, data);
            
            if (data.type === 'nflnews') {
                console.log(`NFL NEWS MESSAGE (${type}):`, {
                    type: data.type,
                    newsCount: data.news?.length ?? 0,
                    newsData: data.news
                });

                const newsItems = Array.isArray(data.news) ? data.news : [];
                if (type === 'news') {
                    setNewsData(newsItems);
                } else if (type === 'latestNews') {
                    setLatestNewsData(newsItems);
                }
            } else {
                switch(data.type) {
                    case 'standings':
                        logDebug("Updating standings with:", data.standings);
                        setStandingsData(Array.isArray(data.standings) ? data.standings : []);
                        break;
                    case 'teamPoints':
                        logDebug("Updating team points with:", data.points);
                        setTeamPointsData(Array.isArray(data.points) ? data.points : []);
                        break;
                    default:
                        logDebug(`Received unexpected message format (${type}):`, data);
                }
            }
        } catch (err) {
            console.error(`Error parsing WebSocket message (${type}):`, err);
            console.error("Problematic message:", event.data);
            setError(`Error processing message from ${type} connection`);
        }
    }, [logDebug]);

    const connectWebSocket = useCallback(async (type) => {
        if (!isAuthenticated) {
            logDebug(`Skipping ${type} connection - user not authenticated`);
            return;
        }

        const baseWsUrl = type === 'news' 
            ? process.env.REACT_APP_NEWS_WEBSOCKET_API_URL
            : process.env.REACT_APP_LATEST_NEWS_WEBSOCKET_API_URL;

        if (!baseWsUrl) {
            setError(`${type} WebSocket configuration error`);
            setConnectionStatus(prev => ({ ...prev, [type]: 'CLOSED' }));
            return;
        }
    
        try {
            logDebug(`Attempting to get access token for ${type}`);
            const accessToken = await getAccessTokenSilently();
            logDebug(`Access token obtained for ${type}`);
            
            const clientId = `web-${type}-${Date.now()}`;
            const wsUrl = `${baseWsUrl}?access_token=${accessToken}&clientId=${clientId}`;
            
            logDebug(`Connecting to WebSocket (${type}):`, baseWsUrl);
            const wsRef = new WebSocket(wsUrl);

            if (type === 'news') {
                newsWebSocketRef.current = wsRef;
            } else {
                latestNewsWebSocketRef.current = wsRef;
            }
    
            wsRef.onopen = () => {
                console.log(`WEBSOCKET: Connection OPENED successfully (${type})`);
                setConnectionStatus(prev => ({ ...prev, [type]: 'OPEN' }));
                if (reconnectTimeoutRef.current[type]) {
                    clearTimeout(reconnectTimeoutRef.current[type]);
                    reconnectTimeoutRef.current[type] = null;
                }
            };
    
            wsRef.onmessage = (event) => {
                console.log(`WEBSOCKET: Raw message received (${type}):`, event.data);
                handleMessage(type, event);
            };
    
            wsRef.onclose = () => {
                console.log(`WEBSOCKET: Connection CLOSED (${type})`);
                setConnectionStatus(prev => ({ ...prev, [type]: 'CLOSED' }));
                reconnectTimeoutRef.current[type] = setTimeout(() => {
                    logDebug(`Attempting to reconnect WebSocket (${type})`);
                    connectWebSocket(type);
                }, 5000);
            };
    
            wsRef.onerror = (error) => {
                console.error(`WEBSOCKET: Connection ERROR (${type}):`, error);
                setError(`${type} connection error. Attempting to reconnect...`);
                setConnectionStatus(prev => ({ ...prev, [type]: 'CLOSED' }));
            };
        } catch (error) {
            console.error(`WEBSOCKET: Error setting up WebSocket (${type}):`, error);
            if (flags?.consoleTrace) {
                console.trace(error);
            }
            setError(`Failed to establish ${type} connection`);
            setConnectionStatus(prev => ({ ...prev, [type]: 'CLOSED' }));
        }
    }, [getAccessTokenSilently, handleMessage, logDebug, flags?.consoleTrace, isAuthenticated]);

    // Initialize connections
    React.useEffect(() => {
        const refs = {
            reconnectTimeouts: reconnectTimeoutRef.current,
            newsWS: newsWebSocketRef.current,
            latestNewsWS: latestNewsWebSocketRef.current
        };

        if (isAuthenticated) {
            logDebug("User is authenticated, setting up WebSocket connections");
            connectWebSocket('news');
            connectWebSocket('latestNews');
        }

        return () => {
            logDebug("Cleaning up WebSocket connections");
            
            Object.keys(refs.reconnectTimeouts).forEach(type => {
                const timeout = refs.reconnectTimeouts[type];
                if (timeout) {
                    clearTimeout(timeout);
                }
            });

            if (refs.newsWS) {
                refs.newsWS.close();
            }
            if (refs.latestNewsWS) {
                refs.latestNewsWS.close();
            }
        };
    }, [connectWebSocket, isAuthenticated, logDebug]);

    const contextValue = {
        newsData,
        latestNewsData,
        standingsData,
        teamPointsData,
        error,
        connectionStatus,
        sendMessage
    };

    return (
        <WebSocketContext.Provider value={contextValue}>
            {children}
        </WebSocketContext.Provider>
    );
});

export const useWebSocket = () => useContext(WebSocketContext);