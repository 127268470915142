import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Alert,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import {
  getAllTrades,
  createTrade,
  updateTrade,
  deleteTrade,
} from "../services/commissioner.service";
import { searchPlayers } from "../services/player.service";
import { useCommissionerCheck } from "../hooks/useCommissionerCheck";

export const TradeManagement = ({
  selectedTeam,
  selectedTeamName,
  theme,
  fontSize,
  currentWeek,
  selectedWeek,
  weeks,
  remainingTrades,
  onTradeUpdate,
  teamRoster,
  capInfo = {},
  formatCurrency,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    isCommissioner,
    loading: isCommissionerLoading,
    error: commissionerError,
  } = useCommissionerCheck();

  // State declarations
  const [trades, setTrades] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentTrade, setCurrentTrade] = useState(null);
  const [error, setError] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [formData, setFormData] = useState({
    week: currentWeek,
    tradeType: "",
    playerAdded: "",
    playerDropped: "",
  });

  // States for player added search only
  const [playerAddedOptions, setPlayerAddedOptions] = useState([]);
  const [playerAddedSearch, setPlayerAddedSearch] = useState("");
  const [showPlayerAddedOptions, setShowPlayerAddedOptions] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tradeToDelete, setTradeToDelete] = useState(null);

  // Only need ref for player added search
  const playerAddedRef = useRef(null);

  const headerFontSize = `${parseFloat(fontSize) * 1.5}px`;

  useEffect(() => {
    console.log("Cap Info Updated:", capInfo);
  }, [capInfo]);

  const getAvailableTradeTypes = useCallback(() => {
    const types = [];

    if ((remainingTrades?.regular || 0) > 0) {
      types.push({ value: "regular", label: "Regular" });
    }

    if ((remainingTrades?.ir || 0) > 0) {
      types.push({ value: "ir", label: "IR" });
    }

    return types;
  }, [remainingTrades]);

  const formatTradeType = (type) => {
    return type === "ir" ? "IR" : "Regular";
  };

  const extractWeekNumber = (weekString) => {
    const match = weekString.match(/\d+/);
    return match ? match[0] : "";
  };

  const fetchTrades = useCallback(async () => {
    if (!isCommissioner) return;
    try {
      const token = await getAccessTokenSilently();
      const response = await getAllTrades(token);
      const filteredTrades = response.data.filter(
        (trade) => trade.managerEmail === selectedTeam
      );
      setTrades(filteredTrades);
    } catch (error) {
      console.error("Failed to fetch trades:", error);
      setError("Failed to fetch trades");
    }
  }, [getAccessTokenSilently, selectedTeam, isCommissioner]);

  useEffect(() => {
    if (selectedTeam && isCommissioner) {
      fetchTrades();
    }
  }, [fetchTrades, selectedTeam, isCommissioner]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        playerAddedRef.current &&
        !playerAddedRef.current.contains(event.target)
      ) {
        setShowPlayerAddedOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "playerDropped") {
      // When dropping player changes, clear the added player
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        playerAdded: "", // Clear the added player
      }));
      setPlayerAddedSearch(""); // Clear the search text
      setPlayerAddedOptions([]); // Clear the search results
      setError(null);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const tradeData = {
        managerEmail: selectedTeam,
        week: currentWeek,
        tradeType: formData.tradeType,
        playerAdded: formData.playerAdded,
        playerDropped: formData.playerDropped,
      };

      if (currentTrade) {
        await updateTrade(token, currentTrade.id, tradeData);
      } else {
        await createTrade(token, tradeData);
      }
      setShowModal(false);
      await Promise.all([fetchTrades(), onTradeUpdate?.()]);
    } catch (error) {
      console.error("Failed to save trade:", error);
      setError(
        "Failed to save trade: " +
          (error.response?.data?.error || error.message)
      );
    }
  };

  const handlePlayerSearch = useCallback(
    async (searchTerm) => {
      if (searchTerm.length < 2) {
        setPlayerAddedOptions([]);
        return;
      }
   
      try {
        setModalLoading(true);
        const token = await getAccessTokenSilently();
   
        // Get the dropped player from roster using the selected ID
        const selectedPlayerId = formData.playerDropped;
        const droppedPlayer = teamRoster.find(
          (p) => String(p.playerID) === String(selectedPlayerId)
        );
   
        // Calculate available space for maxSalary
        const rosterTotalSalary = teamRoster.reduce((total, player) => {
          if (
            droppedPlayer &&
            String(player.playerID) === String(droppedPlayer.playerID)
          ) {
            return total;
          }
          return total + (Number(player.salary) || 0);
        }, 0);
   
        const availableSpace = Number(capInfo.salaryCap || 50) - rosterTotalSalary;
   
        // Normalize position helper function
const normalizePosition = (pos, player) => {
  if (!pos) return null;
  // If it's a FLEX position, use the actual player type if available
  if (pos.startsWith('FLEX') && droppedPlayer) {
    // Get base position type (WR, RB, TE)
    const actualPosition = droppedPlayer.position.match(/^(WR|RB|TE|QB|D)/i)?.[0];
    if (actualPosition) {
      return actualPosition.toLowerCase();
    }
    return 'FLEX';
  }
  if (pos.startsWith('QB')) return 'qb';
  if (pos.startsWith('RB')) return 'rb';
  if (pos.startsWith('WR')) return 'wr';
  if (pos.startsWith('TE')) return 'te';
  if (pos === 'D') return 'd';
  return pos.toLowerCase();
};

// Get the normalized position
const position = droppedPlayer ? normalizePosition(droppedPlayer.position, droppedPlayer) : null;
   
        // Create proper search parameters
        const searchParams = {
          searchTerm: searchTerm,
          maxSalary: availableSpace,
          position: position,
          currentPlayerId: null,
          week: currentWeek
        };
   
        console.log("Search parameters with normalized position:", searchParams);
        
        const { data, error } = await searchPlayers(token, searchParams);
        if (error) {
          throw new Error(error);
        }
   
        const filteredData = data.filter((player) => {
          const playerSalary = Number(player.salary) || 0;
          const wouldFit = playerSalary <= availableSpace;
   
          console.log("Player Cap Check:", {
            playerName: player.name,
            playerSalary,
            availableSpace,
            wouldFit,
          });
   
          return wouldFit;
        });
   
        if (filteredData.length === 0 && data.length > 0) {
          setError(
            `No players found within available cap space (${formatCurrency(
              availableSpace
            )})`
          );
          setPlayerAddedOptions([]);
        } else {
          const formattedData = filteredData.map((player) => ({
            ...player,
            displayName: `${player.name} (${player.position} - ${
              player.teamAbv
            }) - ${formatCurrency(player.salary)}`,
          }));
   
          setPlayerAddedOptions(formattedData);
          setShowPlayerAddedOptions(true);
          setError(null);
        }
      } catch (error) {
        console.error("Failed to search players:", error);
        setError("Failed to search players");
      } finally {
        setModalLoading(false);
      }
    },
    [
      getAccessTokenSilently,
      formatCurrency,
      capInfo,
      teamRoster,
      formData.playerDropped,
      currentWeek
    ]
   );

  const handlePlayerSelect = useCallback((player) => {
    setFormData((prevData) => ({
      ...prevData,
      playerAdded: player.playerID,
    }));
    setPlayerAddedSearch(player.displayName);
    setShowPlayerAddedOptions(false);
  }, []);

  const openModal = (trade = null) => {
    setCurrentTrade(trade);

    if (trade) {
      setFormData({
        week: currentWeek,
        tradeType: trade.tradeType,
        playerAdded: trade.playerAdded,
        playerDropped: trade.playerDropped,
      });
      setPlayerAddedSearch(trade.playerAddedName || "");
    } else {
      const availableTypes = getAvailableTradeTypes();
      setFormData({
        week: currentWeek,
        tradeType: availableTypes.length > 0 ? availableTypes[0].value : "",
        playerAdded: "",
        playerDropped: "",
      });
      setPlayerAddedSearch("");
    }
    setShowModal(true);
  };

  const handleDelete = async (trade) => {
    setTradeToDelete(trade);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      await deleteTrade(token, tradeToDelete.id);
      await Promise.all([fetchTrades(), onTradeUpdate?.()]);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete trade:", error);
      setError("Failed to delete trade");
    }
  };

  const handleClose = useCallback(() => {
    setShowModal(false);
    setPlayerAddedSearch("");
    setPlayerAddedOptions([]);
    setShowPlayerAddedOptions(false);
    setError(null);
  }, []);

  const renderTableRows = () => {
    // Sort trades in descending order by week
    const sortedTrades = [...trades].sort((a, b) => {
      // Extract week numbers for comparison
      const weekA = parseInt(a.week.match(/\d+/)[0]);
      const weekB = parseInt(b.week.match(/\d+/)[0]);
      return weekB - weekA;  // Descending order
    });

    const hasRemainingTrades =
      (remainingTrades?.regular || 0) > 0 || (remainingTrades?.ir || 0) > 0;

      return (
        <>
          {sortedTrades.map((trade) => (
            <tr key={trade.id}>
              <td className="trade-week-column">{trade.week}</td>
              <td className="trade-type-column">
                {formatTradeType(trade.tradeType)}
              </td>
              <td className="trade-player-column">{trade.playerAddedName}</td>
              <td className="trade-player-column">{trade.playerDroppedName}</td>
              <td className="trade-actions-column">
                {selectedWeek === currentWeek && trade.week === currentWeek ? (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      onClick={() => openModal(trade)}
                      className="me-2"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(trade)}
                    >
                      Delete
                    </Button>
                  </>
                ) : null}
              </td>
            </tr>
          ))}
          {selectedWeek === currentWeek && hasRemainingTrades && (
            <tr>
              <td colSpan="5" className="text-center">
                <Button variant="primary" onClick={() => openModal()}>
                  Add Trade
                </Button>
              </td>
            </tr>
          )}
        </>
      );
    };

  if (isCommissionerLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (commissionerError || (!isCommissioner && !isCommissionerLoading)) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="trade-management-wrapper">
      <div className="trade-management-content">
        <h4 style={{ fontSize: headerFontSize }}>
          Trade Management for {selectedTeamName}
        </h4>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="trade-management-table-container">
          <Table
            bordered
            striped
            hover
            size="sm"
            variant={theme}
            className="align-middle"
          >
            <thead>
              <tr>
                <th className="trade-week-column">Week</th>
                <th className="trade-type-column">Trade Type</th>
                <th className="trade-player-column">Player Added</th>
                <th className="trade-player-column">Player Dropped</th>
                <th className="trade-actions-column">Actions</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </div>
      </div>

      {/* Trade Edit/Add Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentTrade ? "Edit Trade" : "Add New Trade"} for{" "}
            {selectedTeamName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Simplified Cap Space Display */}
          <div className="mb-3">
            <h6>Available Cap Space:</h6>
            <div className="p-3 bg-light rounded">
              {formatCurrency(
                (capInfo.availableCap || 0) +
                  (formData.playerDropped
                    ? teamRoster.find(
                        (p) =>
                          String(p.playerID) === String(formData.playerDropped)
                      )?.salary || 0
                    : 0)
              )}
            </div>
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Control
              plaintext
              readOnly
              value={`Week: ${extractWeekNumber(currentWeek)}`}
              className="mb-3"
            />

            {/* Trade Type selection */}
            {!currentTrade && (
              <Form.Group className="mb-3">
                <Form.Label>Trade Type</Form.Label>
                <Form.Control
                  as="select"
                  name="tradeType"
                  value={formData.tradeType}
                  onChange={handleInputChange}
                >
                  {getAvailableTradeTypes().map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label} ({remainingTrades[type.value]} remaining)
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {currentTrade && (
              <Form.Group className="mb-3">
                <Form.Label>Trade Type</Form.Label>
                <Form.Control
                  plaintext
                  readOnly
                  value={formData.tradeType === "ir" ? "IR" : "Regular"}
                />
              </Form.Group>
            )}

            {/* Player Dropped Dropdown */}
            <Form.Group className="mb-3">
              <Form.Label>Player to Drop</Form.Label>
              <Form.Select
                name="playerDropped"
                value={formData.playerDropped}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a player to drop</option>
                {teamRoster
                  .sort((a, b) => {
                    // Define position order
                    const positionOrder = {
                      QB1: 0,
                      QB2: 1,
                      RB1: 2,
                      RB2: 3,
                      RB3: 4,
                      WR1: 5,
                      WR2: 6,
                      WR3: 7,
                      TE1: 8,
                      FLEX1: 9,
                      FLEX2: 10,
                    };

                    // Get position order value, defaulting to a high number if position not found
                    const orderA = positionOrder[a.position] ?? 999;
                    const orderB = positionOrder[b.position] ?? 999;

                    return orderA - orderB;
                  })
                  .map((player) => (
                    <option key={player.playerID} value={player.playerID}>
                      {player.player_name} ({player.position} - {player.teamAbv}
                      ) - {formatCurrency(player.salary)}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            {/* Player Added Search */}
            <Form.Group ref={playerAddedRef}>
              <Form.Label>Player to Add</Form.Label>
              <Form.Control
                type="text"
                value={playerAddedSearch}
                onChange={(e) => {
                  setPlayerAddedSearch(e.target.value);
                  handlePlayerSearch(e.target.value);
                }}
                placeholder="Start typing to search players"
                required
                disabled={modalLoading || !formData.playerDropped}
              />
              {showPlayerAddedOptions && (
                <ListGroup
                  className="position-absolute w-100"
                  style={{ zIndex: 1000 }}
                >
                  {playerAddedOptions.length > 0 ? (
                    playerAddedOptions.map((player, index) => (
                      <ListGroup.Item
                        key={index}
                        action
                        onClick={() => handlePlayerSelect(player)}
                      >
                        {player.displayName}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No matching players found</ListGroup.Item>
                  )}
                </ListGroup>
              )}
            </Form.Group>

            <Button
              type="submit"
              disabled={
                modalLoading || !formData.playerAdded || !formData.playerDropped
              }
              className="mt-3"
            >
              {modalLoading ? "Saving..." : "Save Trade"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this trade?
          <br />
          <strong>Player Added:</strong> {tradeToDelete?.playerAddedName}
          <br />
          <strong>Player Dropped:</strong> {tradeToDelete?.playerDroppedName}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TradeManagement;
