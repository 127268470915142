import { callExternalApi } from "./external-api.service";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getProtectedResource = async (accessToken, position, week) => {
  console.log(`Fetching ${position} players for week ${week}`);
  const config = {
    url: `${apiServerUrl}/api/players/${position.toLowerCase()}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      week: week,
    },
  };
  console.log("API request config:", JSON.stringify(config, null, 2));
  try {
    const { data, error } = await callExternalApi({ config });
    if (error) {
      console.error("Error fetching protected resource:", error);
    } else {
      console.log(`Received ${data?.length || 0} players for ${position}`);
      if (data && data.length > 0) {
        console.log("Sample player data:", data[0]);
      }
    }
    return { data, error };
  } catch (error) {
    console.error("Unexpected error in getProtectedResource:", error);
    return { data: null, error: error.message };
  }
};

export const searchPlayers = async (accessToken, searchParams) => {
  console.log(`Building request with searchParams:`, searchParams);
  const config = {
    url: `${apiServerUrl}/api/players/search`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      term: searchParams.searchTerm,
      maxSalary: searchParams.maxSalary,
      position: searchParams.position, 
      currentPlayerId: searchParams.currentPlayerId,
      week: searchParams.week
    }
  };
  console.log("Final request config:", config.params);
  
  try {
    const response = await callExternalApi({ config });
    return response;
  } catch (error) {
    console.error("Error searching players:", error);
    console.error("Error details:", error.response?.data);
    return { data: null, error: error.message };
  }
};