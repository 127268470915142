import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import SSEManager from "../components/SSEManager";
import {
  getSeasonTotalPoints,
  getAllPlayersFantasyPoints,
  getWeeklyPlayerStats,
  getPlayoffPlayerStats,
} from "../services/fantasyPoints.service";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { Link } from "react-router-dom";

export const DashboardPage = withLDConsumer()(({ flags }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const ldClient = useLDClient();
  const currentSeason = process.env.REACT_APP_CURRENT_SEASON;

  const positions = useMemo(() => ["QB", "RB", "WR", "TE", "Team Defense"], []);
  const ldContextKey = useMemo(
    () => user.sub.replace("auth0|", ""),
    [user.sub]
  );

  const [dashboardData, setDashboardData] = useState({
    topSeasonTeams: [],
    topWeekTeams: [],
    topSeasonPlayers: [],
    topWeekPlayers: [],
    topPositionPlayers: {
      QB: { season: [], week: [] },
      RB: { season: [], week: [] },
      WR: { season: [], week: [] },
      TE: { season: [], week: [] },
      "Team Defense": { season: [], week: [] },
    },
    currentNFLWeek: 1,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    ldClient.identify({
      kind: "user",
      key: ldContextKey,
      name: user.name,
      email: user.email,
    });
  }, [ldClient, ldContextKey, user.name, user.email]);

  const updateDashboardData = useCallback((newData) => {
    setDashboardData((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  const isPlayoffWeek = useCallback((week) => {
    return week >= 15 && week <= 18;
  }, []);

  const getPlayoffPoints = useCallback(
    (weeklyPoints) => {
      return Object.entries(weeklyPoints).reduce((total, [week, points]) => {
        const weekNum = parseInt(week.replace("Week ", ""));
        return isPlayoffWeek(weekNum) ? total + points : total;
      }, 0);
    },
    [isPlayoffWeek]
  );

  const processTeamData = useCallback(
    (regularSeasonData, currentWeek) => {
      let teamsToShow = regularSeasonData;

      // Filter for playoff teams in playoff weeks
      if (isPlayoffWeek(currentWeek)) {
        // Get regular season standings (Weeks 1-14)
        const regularSeasonStandings = regularSeasonData
          .map((team) => ({
            ...team,
            regularSeasonPoints: Object.entries(team.weeklyPoints).reduce(
              (total, [week, points]) => {
                const weekNum = parseInt(week.replace("Week ", ""));
                return weekNum < 15 ? total + points : total;
              },
              0
            ),
          }))
          .sort((a, b) => b.regularSeasonPoints - a.regularSeasonPoints);

        // Take top 6 teams and calculate their playoff points
        teamsToShow = regularSeasonStandings.slice(0, 6).map((team) => ({
          ...team,
          playoffPoints: getPlayoffPoints(team.weeklyPoints),
        }));
      }

      const topSeason = teamsToShow
        .map((team) => ({
          ...team,
          totalPoints: isPlayoffWeek(currentWeek)
            ? team.playoffPoints || getPlayoffPoints(team.weeklyPoints)
            : team.totalPoints,
        }))
        .sort((a, b) => b.totalPoints - a.totalPoints)
        .slice(0, 10);

      const topWeek = teamsToShow
        .map((team) => ({
          ...team,
          weekPoints: team.weeklyPoints[`Week ${currentWeek}`] || 0,
        }))
        .sort((a, b) => b.weekPoints - a.weekPoints)
        .slice(0, 10);

      return { topSeason, topWeek };
    },
    [isPlayoffWeek, getPlayoffPoints]
  );

  const fetchSeasonData = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      // Get season data first
      const seasonResponse = await getSeasonTotalPoints(accessToken);
      if (seasonResponse.error) {
        throw new Error(seasonResponse.error);
      }

      const currentWeek = seasonResponse.currentNFLWeek;
      const updates = {
        currentNFLWeek: currentWeek,
      };

      // Process team data
      if (Array.isArray(seasonResponse.regularSeasonData)) {
        const { topSeason, topWeek } = processTeamData(
          seasonResponse.regularSeasonData,
          currentWeek
        );
        updates.topSeasonTeams = topSeason;
        updates.topWeekTeams = topWeek;
      }

      // Get player stats based on whether we're in playoffs or not
      const [playersResponse, weeklyStatsResponse] = await Promise.all([
        isPlayoffWeek(currentWeek)
          ? getPlayoffPlayerStats(accessToken)
          : getAllPlayersFantasyPoints(accessToken),
        getWeeklyPlayerStats(accessToken, currentWeek.toString()),
      ]);

      console.log("Players Response:", playersResponse);
      console.log("Weekly Stats Response:", weeklyStatsResponse);

      // Process season/playoff stats
      const playerData = playersResponse.data?.data || [];
      if (Array.isArray(playerData)) {
        // For playoff stats (Weeks 15-18), include players who have data in any playoff week
        const playersWhoPlayed = isPlayoffWeek(currentWeek)
          ? playerData.filter((player) => {
              console.log("Playoff Player:", player);
              return Object.keys(player.weeklyPoints || {}).some((week) => {
                const weekNum = parseInt(week.replace("Week ", ""));
                return weekNum >= 15 && weekNum <= 18;
              });
            })
          : playerData;

        console.log("Playoff Players Filtered:", playersWhoPlayed);

        updates.topSeasonPlayers = playersWhoPlayed
          .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
          .slice(0, 10);

        const positionData = {};
        positions.forEach((position) => {
          const filteredPlayers =
            position === "Team Defense"
              ? playersWhoPlayed.filter(
                  (player) => player.position === "Team Defense"
                )
              : playersWhoPlayed.filter(
                  (player) => player.position === position
                );

          positionData[position] = {
            season: filteredPlayers
              .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
              .slice(0, 10),
            week: [],
          };
        });
        updates.topPositionPlayers = positionData;
      }

      console.log("Full Weekly Stats Response:", weeklyStatsResponse);

      // Process weekly stats
      if (Array.isArray(weeklyStatsResponse?.data)) {
        console.log(
          "Raw Weekly Stats Data:",
          weeklyStatsResponse.data.map((player) => ({
            playerName: player.playerName,
            team: player.team,
            teamAbv: player.teamAbv,
            position: player.position,
            totalFantasyPoints: player.totalFantasyPoints,
          }))
        );

        const weeklyPlayers = weeklyStatsResponse.data
          .filter(
            (player) =>
              player.hasOwnProperty("totalFantasyPoints") &&
              player.totalFantasyPoints > 0 &&
              player.playerName !== null &&
              player.position !== "Unknown"
          )
          .map((player) => ({
            ...player,
            teamAbv: player.team, // Explicitly map team to teamAbv
          }));

        updates.topWeekPlayers = weeklyPlayers
          .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
          .slice(0, 10);

        positions.forEach((position) => {
          const filteredPlayers =
            position === "Team Defense"
              ? weeklyPlayers.filter((player) => player.position === "DEF")
              : weeklyPlayers.filter((player) => player.position === position);

          if (
            updates.topPositionPlayers &&
            updates.topPositionPlayers[position]
          ) {
            updates.topPositionPlayers[position].week = filteredPlayers
              .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
              .slice(0, 10);
          }
        });
      }

      updateDashboardData(updates);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(`Error fetching data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [
    getAccessTokenSilently,
    positions,
    processTeamData,
    updateDashboardData,
    isPlayoffWeek,
  ]);

  const handleSSEMessage = useCallback(
    (data) => {
      console.log("SSE Message received:", data);
      try {
        let updates = {};

        if (data.type === "standings") {
          const { topSeason, topWeek } = processTeamData(
            data.data.regularSeasonData,
            data.data.currentNFLWeek
          );
          updates = {
            currentNFLWeek: data.data.currentNFLWeek,
            topSeasonTeams: topSeason,
            topWeekTeams: topWeek,
          };
        }

        if (data.type === "playerStats") {
          // During playoff weeks, ignore SSE player stats updates
          // The initial fetch will have set the correct playoff stats
          if (!isPlayoffWeek(dashboardData.currentNFLWeek)) {
            if (data.data.seasonStats) {
              updates.topSeasonPlayers = data.data.seasonStats
                .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
                .slice(0, 10);

              const positionData = {};
              positions.forEach((position) => {
                const filteredPlayers =
                  position === "Team Defense"
                    ? data.data.seasonStats.filter(
                        (player) =>
                          player.position === "DEF" ||
                          player.position === "Team Defense"
                      )
                    : data.data.seasonStats.filter(
                        (player) => player.position === position
                      );

                positionData[position] = {
                  season: filteredPlayers
                    .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
                    .slice(0, 10),
                  week: [],
                };
              });
              updates.topPositionPlayers = positionData;
            }
          }

          if (data.data.weeklyStats) {
            updates.topWeekPlayers = data.data.weeklyStats
              .map((player) => ({
                ...player,
                teamAbv: player.team, // Ensure teamAbv is set
              }))
              .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
              .slice(0, 10);

            positions.forEach((position) => {
              const filteredPlayers =
                position === "Team Defense"
                  ? updates.topWeekPlayers.filter(
                      (player) => player.position === "DEF"
                    )
                  : updates.topWeekPlayers.filter(
                      (player) => player.position === position
                    );

              if (
                updates.topPositionPlayers &&
                updates.topPositionPlayers[position]
              ) {
                updates.topPositionPlayers[position].week = filteredPlayers
                  .sort((a, b) => b.totalFantasyPoints - a.totalFantasyPoints)
                  .slice(0, 10);
              }
            });
          }
        }

        if (Object.keys(updates).length > 0) {
          updateDashboardData(updates);
        }
      } catch (error) {
        console.error("Error processing SSE message:", error);
        setError(`Error processing live updates: ${error.message}`);
      }
    },
    [
      positions,
      processTeamData,
      updateDashboardData,
      dashboardData.currentNFLWeek,
      isPlayoffWeek,
    ]
  );

  useEffect(() => {
    const SSE_ENDPOINT = "/api/fantasypoints/sse";

    const setupSSEConnection = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        SSEManager.setAccessToken(accessToken);
        SSEManager.addListener(SSE_ENDPOINT, handleSSEMessage);
        console.log("SSE connection established");
      } catch (err) {
        console.error("Error setting up SSE:", err);
        setError(`Error setting up live updates: ${err.message}`);
      }
    };

    // Initial setup
    setupSSEConnection();

    // Cleanup
    return () => {
      SSEManager.removeListener(SSE_ENDPOINT, handleSSEMessage);
    };
  }, [getAccessTokenSilently, handleSSEMessage]);

  // Separate effect for initial data fetch
  useEffect(() => {
    fetchSeasonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array since we only want this to run once

  const calculatePreviousRankings = useCallback(
    (tableData) => {
      if (!tableData || tableData.length === 0) return new Map();

      // For week rankings, we'll compare against previous week
      // For season rankings, we'll calculate rankings without current week points
      const prevWeekData = tableData.map((manager) => ({
        ...manager,
        totalPoints: Object.entries(manager.weeklyPoints).reduce(
          (sum, [week, points]) => {
            const weekNum = parseInt(week.replace("Week ", ""));
            if (weekNum < dashboardData.currentNFLWeek) {
              return sum + points;
            }
            return sum;
          },
          0
        ),
      }));

      prevWeekData.sort((a, b) => b.totalPoints - a.totalPoints);

      const rankMap = new Map();
      prevWeekData.forEach((manager, index) => {
        rankMap.set(manager.managerEmail, index + 1);
      });

      return rankMap;
    },
    [dashboardData.currentNFLWeek]
  );

  const shouldShowRankChange = useCallback(
    (pointsKey) => {
      // Only show rank changes for season totals during playoff weeks 16-18
      return (
        pointsKey === "totalPoints" &&
        isPlayoffWeek(dashboardData.currentNFLWeek) &&
        dashboardData.currentNFLWeek >= 16
      );
    },
    [dashboardData.currentNFLWeek, isPlayoffWeek]
  );

  const renderTeamTable = useCallback(
    (title, data, pointsKey) => {
      const showRankChange = shouldShowRankChange(pointsKey);
      const previousRankings = showRankChange
        ? calculatePreviousRankings(data)
        : new Map();

      return (
        <div className="dashboard-table-container">
          <h4
            className="dashboard-table-title"
            title={title}
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>
          <div className="table-responsive">
            <Table
              striped
              bordered
              hover
              size="sm"
              variant={theme}
              className="align-middle"
            >
              <thead>
                <tr>
                  <th className="dashboard-rank-column">Rank</th>
                  {showRankChange && (
                    <th className="dashboard-rank-change-column"></th>
                  )}
                  <th className="dashboard-team-column">Team</th>
                  <th className="dashboard-points-column">Points</th>
                </tr>
              </thead>
              <tbody>
                {data.map((team, index) => (
                  <tr key={team.managerEmail}>
                    <td className="dashboard-rank-column">{index + 1}</td>
                    {showRankChange && (
                      <td
                        className="dashboard-rank-change-column"
                        style={{ padding: 0, whiteSpace: "nowrap" }}
                      >
                        {(() => {
                          const previousRank = previousRankings.get(
                            team.managerEmail
                          );
                          if (previousRank === index + 1) return null;
                          if (previousRank > index + 1) {
                            return (
                              <CaretUpFill className="text-success" size={12} />
                            );
                          }
                          return (
                            <CaretDownFill className="text-danger" size={12} />
                          );
                        })()}
                      </td>
                    )}
                    <td className="dashboard-team-column">
                      <Link
                        to={`/fantasypoints/${encodeURIComponent(
                          team.teamName || team.managerEmail
                        )}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {team.teamName || team.managerEmail}
                      </Link>
                    </td>
                    <td className="dashboard-points-column">
                      {pointsKey === "weekPoints"
                        ? team.weekPoints
                        : team[pointsKey]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    },
    [fontSize, theme, shouldShowRankChange, calculatePreviousRankings]
  );

  const renderPlayerTable = useCallback(
    (title, data, isWeekly = false, isPositionSpecific = false) => {
      return (
        <div className="dashboard-table-container">
          <h4
            className="dashboard-table-title"
            title={title}
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>
          <Table
            striped
            bordered
            hover
            size="sm"
            variant={theme}
            className="align-middle"
          >
            <thead>
              <tr>
                <th>Rank</th>
                <th>Player Name</th>
                <th>NFL Team</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {data.map((player, index) => (
                <tr key={player.playerId || index}>
                  <td>{index + 1}</td>
                  <td>{player.playerName}</td>
                  <td>{player.teamAbv}</td>
                  <td>{player.totalFantasyPoints}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    },
    [theme, fontSize]
  );

  const renderPositionTables = useCallback(() => {
    return (
      <Tabs defaultActiveKey="QB" id="position-tabs" className="mb-3">
        {positions.map((position) => (
          <Tab eventKey={position} title={position} key={position}>
            <Row>
              <Col md={6}>
                {renderPlayerTable(
                  `Top 10 ${position === "Team Defense" ? "DEF" : position} - ${
                    isPlayoffWeek(dashboardData.currentNFLWeek)
                      ? `${currentSeason} Playoffs`
                      : `${currentSeason} Season`
                  }`,
                  dashboardData.topPositionPlayers[position]?.season || [],
                  false,
                  true
                )}
              </Col>
              <Col md={6}>
                {renderPlayerTable(
                  `Top 10 ${
                    position === "Team Defense" ? "DEF" : position
                  } - Week ${dashboardData.currentNFLWeek}`,
                  dashboardData.topPositionPlayers[position]?.week || [],
                  true,
                  true
                )}
              </Col>
            </Row>
          </Tab>
        ))}
      </Tabs>
    );
  }, [
    positions,
    currentSeason,
    dashboardData.currentNFLWeek,
    dashboardData.topPositionPlayers,
    renderPlayerTable,
    isPlayoffWeek,
  ]);

  const getTableTitle = useCallback(
    (baseTitle, isWeekly = false) => {
      if (isWeekly) {
        return `${baseTitle} - Week ${dashboardData.currentNFLWeek}`;
      }

      const yearPart = isPlayoffWeek(dashboardData.currentNFLWeek)
        ? `${currentSeason} Playoffs`
        : `${currentSeason} Season`;

      return `${baseTitle} - ${yearPart}`;
    },
    [currentSeason, dashboardData.currentNFLWeek, isPlayoffWeek]
  );

  if (loading) {
    return (
      <Container fluid>
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  if (!flags.dashboardComponent) {
    return (
      <Container fluid>
        <br />
        <h4 style={{ fontSize: `calc(${fontSize}px * 1.5)` }}>
          TUFF Dashboard
        </h4>
        <br />
        <h1>The TUFF Dashboard is currently disabled.</h1>
      </Container>
    );
  }

  return (
    <div className="dashboard-wrapper">
      <Container fluid>
        <br />
        <h4 style={{ fontSize: `calc(${fontSize}px * 1.5)` }}>
          TUFF Dashboard{" "}
          {isPlayoffWeek(dashboardData.currentNFLWeek) ? "- Playoffs" : ""}
        </h4>
        <br />

        {error && <Alert variant="danger">{error}</Alert>}

        <div className="dashboard-content">
          <Row className="justify-content-center">
            <Col md={6}>
              {renderTeamTable(
                getTableTitle("Top 10 Fantasy Points"),
                dashboardData.topSeasonTeams,
                "totalPoints"
              )}
            </Col>
            <Col md={6}>
              {renderTeamTable(
                getTableTitle("Top 10 Fantasy Points", true),
                dashboardData.topWeekTeams,
                "weekPoints"
              )}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={6}>
              {renderPlayerTable(
                getTableTitle("Top 10 Players"),
                dashboardData.topSeasonPlayers,
                console.log(
                  "Top Season Players:",
                  dashboardData.topSeasonPlayers
                )
              )}
            </Col>
            <Col md={6}>
              {renderPlayerTable(
                getTableTitle("Top 10 Players", true),
                dashboardData.topWeekPlayers,
                true
              )}
            </Col>
          </Row>

          <section className="position-details">
            {renderPositionTables()}
          </section>
        </div>
      </Container>
    </div>
  );
});

export default DashboardPage;
