const apiBaseUrl = process.env.REACT_APP_API_SERVER_URL;
const sseApiBaseUrl = process.env.REACT_APP_SSE_API_URL;

const sseEndpoints = ["/api/nflnews/sse/latest", "/api/standings/sse"];

class SSEManager {
  constructor() {
    this.eventSources = new Map();
    this.listeners = new Map();
    this.accessToken = null;
    this.reconnectTimeouts = new Map();
    this.maxReconnectAttempts = 5;
    this.reconnectAttempts = new Map();
    this.connecting = new Set(); // Track ongoing connections
  }

  setAccessToken(token) {
    this.accessToken = token;
  }

  connect(endpoint) {
    console.log(`SSEManager connect method called for endpoint: ${endpoint}`);
    if (this.eventSources.has(endpoint) || this.connecting.has(endpoint)) {
      console.log(
        `SSE connection already exists or is in progress for endpoint: ${endpoint}`
      );
      return;
    }

    if (!this.accessToken) {
      console.error("No access token available. Cannot connect.");
      return;
    }

    this.connecting.add(endpoint);
    this.createEventSource(endpoint);
  }

  createEventSource(endpoint) {
    const clientId = `client-${Date.now()}`;
    console.log(
      `Attempting to create EventSource for endpoint: ${endpoint} with client ID: ${clientId}`
    );

    try {
      let url;
      if (sseEndpoints.includes(endpoint)) {
        url = new URL(`${sseApiBaseUrl}${endpoint}`);
      } else {
        url = new URL(
          endpoint.startsWith("/api/")
            ? `${apiBaseUrl}${endpoint}`
            : `${apiBaseUrl}/api${endpoint}`
        );
      }

      url.searchParams.append("clientId", clientId);
      url.searchParams.append("access_token", this.accessToken);

      console.log(`Connecting to SSE at URL: ${url.toString()}`);

      const eventSource = new EventSource(url);

      eventSource.onopen = () => {
        console.log(`SSE connection opened for endpoint: ${endpoint}`);
        this.reconnectAttempts.set(endpoint, 0);
        this.connecting.delete(endpoint);
      };

      eventSource.onmessage = (event) => {
        console.log(
          `SSE message received for endpoint: ${endpoint}:`,
          event.data
        );
        try {
          const data = JSON.parse(event.data);
          if (this.listeners.has(endpoint)) {
            this.listeners.get(endpoint).forEach((listener) => listener(data));
          }
        } catch (error) {
          console.error(
            `Error parsing SSE message for endpoint: ${endpoint}:`,
            error
          );
        }
      };

      eventSource.onerror = (error) => {
        console.error(`SSE error for endpoint: ${endpoint}:`, error);
        this.handleConnectionError(endpoint, eventSource);
        this.connecting.delete(endpoint);
      };

      this.eventSources.set(endpoint, eventSource);
    } catch (error) {
      console.error(
        `Error creating EventSource for endpoint: ${endpoint}:`,
        error
      );
      this.scheduleReconnect(endpoint);
    }
  }

  handleConnectionError(endpoint, eventSource) {
    console.log(`Handling connection error for endpoint: ${endpoint}`);
    eventSource.close();
    this.eventSources.delete(endpoint);

    if (this.connecting.has(endpoint)) {
      this.connecting.delete(endpoint);
    }

    this.scheduleReconnect(endpoint);
  }

  scheduleReconnect(endpoint) {
    if (this.reconnectTimeouts.has(endpoint) || this.connecting.has(endpoint)) {
      console.log(
        `Reconnection already scheduled or in progress for endpoint: ${endpoint}`
      );
      return;
    }

    const attempts = (this.reconnectAttempts.get(endpoint) || 0) + 1;
    this.reconnectAttempts.set(endpoint, attempts);

    if (attempts <= this.maxReconnectAttempts) {
      const delay = Math.min(1000 * Math.pow(2, attempts - 1), 30000);
      console.log(
        `Scheduling reconnection for endpoint: ${endpoint}. Attempt ${attempts} in ${delay}ms`
      );

      const timeoutId = setTimeout(() => {
        this.reconnectTimeouts.delete(endpoint);
        this.createEventSource(endpoint);
      }, delay);

      this.reconnectTimeouts.set(endpoint, timeoutId);
    } else {
      console.error(
        `Max reconnection attempts reached for endpoint: ${endpoint}. Giving up.`
      );
      this.connecting.delete(endpoint);
    }
  }

  addListener(endpoint, listener) {
    if (!this.listeners.has(endpoint)) {
      this.listeners.set(endpoint, new Set());
    }
    this.listeners.get(endpoint).add(listener);
    if (!this.eventSources.has(endpoint)) {
      this.connect(endpoint);
    }
  }

  removeListener(endpoint, listener) {
    if (this.listeners.has(endpoint)) {
      this.listeners.get(endpoint).delete(listener);
      if (this.listeners.get(endpoint).size === 0) {
        this.disconnect(endpoint);
      }
    }
  }

  disconnect(endpoint) {
    console.log(`Disconnecting from endpoint: ${endpoint}`);
    if (this.eventSources.has(endpoint)) {
      this.eventSources.get(endpoint).close();
      this.eventSources.delete(endpoint);
    }
    this.listeners.delete(endpoint);
    if (this.reconnectTimeouts.has(endpoint)) {
      clearTimeout(this.reconnectTimeouts.get(endpoint));
      this.reconnectTimeouts.delete(endpoint);
    }
    this.reconnectAttempts.delete(endpoint);
    this.connecting.delete(endpoint);
  }

  disconnectAll() {
    console.log("Disconnecting all SSE connections");
    this.eventSources.forEach((_, endpoint) => this.disconnect(endpoint));
    this.reconnectTimeouts.forEach((timeout) => clearTimeout(timeout));
    this.reconnectTimeouts.clear();
  }
}

const sseManager = new SSEManager();
export default sseManager;
