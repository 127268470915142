import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Alert,
  Spinner,
  ButtonGroup,
  ToggleButton,
  Form,
} from "react-bootstrap";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { getStandingsData } from "../services/fantasyPoints.service";
import { useTheme } from "../contexts/ThemeContext";
import { useFontSize } from "../contexts/FontSizeContext";
import { StyledTable } from "../components/StyledTable";
import SSEManager from "../components/SSEManager";

// Constants moved outside component
const REGULAR_SEASON_WEEKS = 14;
const SSE_ENDPOINT = "/api/tuffstandings/sse/tuffstandings";
const MAX_RETRIES = 5;
const RETRY_DELAY = 5000;

export const TUFFStandingsPage = () => {
  const { theme } = useTheme();
  const { fontSize } = useFontSize();
  const { getAccessTokenSilently } = useAuth0();

  // State definitions
  const [isProcessing, setIsProcessing] = useState(true);
  const [seasonData, setSeasonData] = useState({
    regularSeason: [],
    playoffs: [],
  });
  const [currentNFLWeek, setCurrentNFLWeek] = useState(1);
  const [weeklyRankings, setWeeklyRankings] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [weekViewMode, setWeekViewMode] = useState("recent");
  const [showRegularSeasonWeeks, setShowRegularSeasonWeeks] = useState(false);

  // Memoized values
  const headerFontSize = useMemo(
    () => `${parseFloat(fontSize) * 1.5}px`,
    [fontSize]
  );

  const legendItems = useMemo(
    () => [
      { rank: "GOTW", bootstrapClass: "primary" },
      { rank: "2nd Place", bootstrapClass: "warning" },
      { rank: "3rd Place", bootstrapClass: "danger" },
      { rank: "4th Place", bootstrapClass: "info" },
      { rank: "Top 10", bootstrapClass: "success" },
    ],
    []
  );

  // Memoized helper functions
  const getColorForRank = useCallback((rank) => {
    if (rank === 1) return "primary";
    if (rank === 2) return "warning";
    if (rank === 3) return "danger";
    if (rank === 4) return "info";
    if (rank >= 5 && rank <= 10) return "success";
    return "";
  }, []);

  const formatDollarAmount = useCallback((amount) => {
    return amount % 1 === 0 ? `$${amount}` : `$${amount.toFixed(2)}`;
  }, []);

  // Memoize the parsed weekly rankings
  const parsedWeeklyRankings = useMemo(() => {
    try {
      return weeklyRankings;
    } catch (e) {
      console.error("Error parsing weekly rankings:", e);
      return {};
    }
  }, [weeklyRankings]);

  const calculateDisplayWeeks = useCallback(
    (isPlayoff, currentWeek) => {
      if (isPlayoff) {
        // Start with week 15 (first playoff week)
        const startWeek = REGULAR_SEASON_WEEKS + 1;
        // Only show up to the current week, max of 18
        const endWeek = Math.min(currentWeek, 18);
        // Create array of weeks from start to current
        return Array.from(
          { length: Math.max(0, endWeek - startWeek + 1) },
          (_, i) => `Week ${startWeek + i}`
        );
      }

      // Regular season logic remains the same
      const showBonusAndPlayoffColumns = currentWeek > REGULAR_SEASON_WEEKS;

      if (showBonusAndPlayoffColumns && showRegularSeasonWeeks) {
        return Array.from(
          { length: REGULAR_SEASON_WEEKS },
          (_, i) => `Week ${i + 1}`
        );
      }

      if (showBonusAndPlayoffColumns && !showRegularSeasonWeeks) {
        return [];
      }

      const effectiveWeek = Math.min(currentWeek, REGULAR_SEASON_WEEKS);
      const allWeeks = Array.from(
        { length: effectiveWeek },
        (_, i) => `Week ${i + 1}`
      );

      return weekViewMode === "all" ? allWeeks : allWeeks.slice(-4);
    },
    [weekViewMode, showRegularSeasonWeeks]
  );

  // Initial data fetch with regular season protection
  const fetchSeasonData = useCallback(async () => {
    setLoading(true);
    setIsProcessing(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await getStandingsData(accessToken);
      console.log("Data received in component:", response);

      if (response.error) throw new Error(response.error);

      setCurrentNFLWeek(response.currentNFLWeek);

      // Filter weekly rankings to only include up to week 14 for regular season
      const regularSeasonRankings = {};
      Object.keys(response.weeklyRankings || {}).forEach((week) => {
        const weekNum = parseInt(week.split(" ")[1]);
        if (weekNum <= REGULAR_SEASON_WEEKS) {
          regularSeasonRankings[week] = response.weeklyRankings[week];
        }
      });

      // Set initial data
      setSeasonData({
        regularSeason: response.regularSeasonData,
        playoffs:
          response.currentNFLWeek > REGULAR_SEASON_WEEKS
            ? response.playoffData
            : [],
      });

      setWeeklyRankings(regularSeasonRankings);
    } catch (err) {
      console.error("Error fetching season data:", err);
      setError(`Error fetching season data: ${err.message}`);
    } finally {
      setLoading(false);
      setIsProcessing(false);
    }
  }, [getAccessTokenSilently]);

  // Handle SSE message with regular season protection
  const handleSSEMessage = useCallback((data) => {
    if (data.type === "standings") {
      setIsProcessing(true);
      try {
        setCurrentNFLWeek(data.data.currentNFLWeek);

        // Only update playoffs data from SSE when in playoff weeks
        if (data.data.currentNFLWeek > REGULAR_SEASON_WEEKS) {
          // Sort and enrich playoff data
          const sortedPlayoffData = [...data.data.playoffData].sort(
            (a, b) => b.totalPlayoffPoints - a.totalPlayoffPoints
          );

          // Add rankings to playoff data
          const enrichedPlayoffData = sortedPlayoffData.map(
            (manager, index) => ({
              ...manager,
              rankings: {
                currentRank: index + 1,
                previousRank: index + 1, // Or calculate this if needed
                pointsFromFirst:
                  sortedPlayoffData[0].totalPlayoffPoints -
                  manager.totalPlayoffPoints,
              },
            })
          );

          setSeasonData((prevData) => ({
            regularSeason: prevData.regularSeason,
            playoffs: enrichedPlayoffData,
          }));

          // Rest of your weekly rankings update code...
          const playoffWeeklyRankings = {};
          Object.keys(data.data.weeklyRankings || {}).forEach((week) => {
            const weekNum = parseInt(week.split(" ")[1]);
            if (weekNum > REGULAR_SEASON_WEEKS) {
              playoffWeeklyRankings[week] = data.data.weeklyRankings[week];
            }
          });

          setWeeklyRankings((prevRankings) => ({
            ...prevRankings,
            ...playoffWeeklyRankings,
          }));
        }
      } catch (error) {
        console.error("Error processing SSE message:", error);
        setError(`Error processing live updates: ${error.message}`);
      } finally {
        setIsProcessing(false);
      }
    }
  }, []);

  // Table rendering
  const renderTable = useCallback(
    (data, title, isPlayoff = false) => {
      const displayWeeks = calculateDisplayWeeks(isPlayoff, currentNFLWeek);
      const showBonusAndPlayoffColumns = currentNFLWeek > REGULAR_SEASON_WEEKS;

      const showWeekColumns =
        isPlayoff ||
        !showBonusAndPlayoffColumns ||
        (showBonusAndPlayoffColumns && showRegularSeasonWeeks);

      return (
        <div className="tuff-standings-wrapper">
          <h4
            className="dashboard-table-title"
            style={{ fontSize: `calc(${fontSize}px * 1.5)` }}
          >
            {title}
          </h4>

          {/* Replace ButtonGroup with Form.Check for regular season table after Week 14 */}
          {!isPlayoff && showBonusAndPlayoffColumns && (
            <div className="mb-3 text-center d-flex justify-content-center align-items-center">
              <Form.Check
                type="switch"
                id="week-view-switch"
                label="Show All Weeks"
                checked={showRegularSeasonWeeks}
                onChange={(e) => setShowRegularSeasonWeeks(e.target.checked)}
                style={{ fontSize: fontSize }}
              />
            </div>
          )}

          {/* Original week view toggle for pre-Week 14 */}
          {!isPlayoff && !showBonusAndPlayoffColumns && (
            <div className="mb-3 text-center d-none d-md-flex justify-content-center position-relative">
              <ButtonGroup size="sm">
                <ToggleButton
                  id="recent-weeks"
                  type="radio"
                  variant={theme === "dark" ? "outline-light" : "outline-dark"}
                  name="week-view"
                  value="recent"
                  checked={weekViewMode === "recent"}
                  onChange={(e) => setWeekViewMode(e.currentTarget.value)}
                  style={{ fontSize: fontSize }}
                >
                  Recent Weeks
                </ToggleButton>
                <ToggleButton
                  id="all-weeks"
                  type="radio"
                  variant={theme === "dark" ? "outline-light" : "outline-dark"}
                  name="week-view"
                  value="all"
                  checked={weekViewMode === "all"}
                  onChange={(e) => setWeekViewMode(e.currentTarget.value)}
                  style={{ fontSize: fontSize }}
                >
                  All Weeks
                </ToggleButton>
              </ButtonGroup>
              {weekViewMode === "recent" && (
                <div
                  className="position-absolute start-50 translate-middle-x"
                  style={{ top: "100%", fontSize: fontSize }}
                >
                  <small className="text-muted">
                    Showing last 3 weeks + current week
                  </small>
                </div>
              )}
            </div>
          )}
          <div className="table-responsive">
            <StyledTable
              bordered
              size="sm"
              variant={theme}
              className="align-middle"
            >
              <thead>
                <tr>
                  <th className="dashboard-rank-column">Rank</th>
                  {(isPlayoff || !showBonusAndPlayoffColumns) && (
                    <th className="dashboard-rank-change-column"></th>
                  )}
                  <th className="dashboard-team-column">Team</th>
                  {showWeekColumns && (
                    <>
                      <th className="mobile-only dashboard-week-column">
                        {displayWeeks[displayWeeks.length - 1]}
                      </th>
                      {displayWeeks.map((week) => (
                        <th
                          key={week}
                          className="desktop-only dashboard-week-column"
                        >
                          {week}
                        </th>
                      ))}
                    </>
                  )}
                  <th className="dashboard-points-column">
                    {isPlayoff ? "Playoff Total" : "Season Total"}
                  </th>
                  {!isPlayoff ? (
                    <>
                      <th className="dashboard-points-column">Points Back</th>
                      <th className="dashboard-points-column">
                        Points from 6th
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        Top 10
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        GOTW
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        2nd Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        3rd Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        4th Place
                      </th>
                      <th className="desktop-only dashboard-points-column">
                        Regular Season $
                      </th>
                      {showBonusAndPlayoffColumns && (
                        <>
                          <th className="desktop-only dashboard-points-column">
                            Reg Season Bonus $
                          </th>
                          <th className="desktop-only dashboard-points-column">
                            Total Regular Season $
                          </th>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <th className="dashboard-points-column">Points Back</th>
                      <th className="desktop-only dashboard-points-column">
                        Playoff $
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((manager) => {
                  const rankings = manager?.rankings || {};
                  const achievements = manager?.achievements || {};
                  const earnings = manager?.earnings || {};
                  const weeklyPoints = manager?.weeklyPoints || {};
                  const totalRegularSeasonEarnings =
                    (earnings.regularSeasonEarnings || 0) +
                    (earnings.bonusPayout || 0);

                  // Calculate total playoff points if in playoff mode
                  const totalPlayoffPoints = isPlayoff
                    ? Object.entries(weeklyPoints)
                        .filter(
                          ([week]) =>
                            parseInt(week.split(" ")[1]) > REGULAR_SEASON_WEEKS
                        )
                        .reduce((sum, [, points]) => sum + (points || 0), 0)
                    : 0;

                  // Calculate points back for playoffs
                  let pointsBack = 0;
                  if (isPlayoff) {
                    const maxPoints = Math.max(
                      ...data.map((m) =>
                        Object.entries(m.weeklyPoints || {})
                          .filter(
                            ([week]) =>
                              parseInt(week.split(" ")[1]) >
                              REGULAR_SEASON_WEEKS
                          )
                          .reduce((sum, [, points]) => sum + (points || 0), 0)
                      )
                    );
                    pointsBack = maxPoints - totalPlayoffPoints;
                  }

                  return (
                    <tr key={manager.managerEmail}>
                      <td className="dashboard-rank-column">
                        {rankings?.currentRank || "-"}
                      </td>
                      {(isPlayoff || !showBonusAndPlayoffColumns) && (
                        <td className="dashboard-rank-change-column">
                          {!isPlayoff &&
                            currentNFLWeek > 1 &&
                            rankings.previousRank !== rankings.currentRank &&
                            (rankings.previousRank > rankings.currentRank ? (
                              <CaretUpFill className="text-success" size={12} />
                            ) : (
                              <CaretDownFill
                                className="text-danger"
                                size={12}
                              />
                            ))}
                        </td>
                      )}
                      <td>
                        <Link
                          to={`/fantasypoints/${encodeURIComponent(
                            manager.teamName || manager.managerEmail
                          )}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {manager.teamName || manager.managerEmail}
                        </Link>
                      </td>
                      {showWeekColumns && (
                        <>
                          <td className="mobile-only">
                            {(() => {
                              const weeklyPointsValue =
                                weeklyPoints[
                                  displayWeeks[displayWeeks.length - 1]
                                ] || 0;
                              const rank =
                                parsedWeeklyRankings[
                                  displayWeeks[displayWeeks.length - 1]
                                ]?.[manager.managerEmail];
                              const colorClass =
                                !isPlayoff && weeklyPointsValue > 0
                                  ? getColorForRank(rank)
                                  : "";
                              return (
                                <div
                                  className={`mobile-week-points ${
                                    colorClass ? `custom-bg ${colorClass}` : ""
                                  }`}
                                >
                                  {weeklyPointsValue}
                                </div>
                              );
                            })()}
                          </td>
                          {displayWeeks.map((week) => {
                            const weeklyPointsValue = weeklyPoints[week] || 0;
                            const rank =
                              parsedWeeklyRankings[week]?.[
                                manager.managerEmail
                              ];
                            const colorClass =
                              !isPlayoff && weeklyPointsValue > 0
                                ? getColorForRank(rank)
                                : "";
                            return (
                              <td
                                key={week}
                                className={`desktop-only dashboard-week-column ${
                                  colorClass ? `custom-bg ${colorClass}` : ""
                                }`}
                              >
                                {weeklyPointsValue}
                              </td>
                            );
                          })}
                        </>
                      )}
                      <td>
                        {isPlayoff
                          ? totalPlayoffPoints
                          : manager.totalPoints || 0}
                      </td>
                      {!isPlayoff ? (
                        <>
                          <td>{rankings.pointsFromFirst || 0}</td>
                          <td>{rankings.pointsFromSixth || 0}</td>
                          <td className="desktop-only">
                            {achievements.top10Count || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.gotwCount || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.secondPlaceCount || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.thirdPlaceCount || 0}
                          </td>
                          <td className="desktop-only">
                            {achievements.fourthPlaceCount || 0}
                          </td>
                          <td className="desktop-only">
                            {formatDollarAmount(
                              earnings.regularSeasonEarnings || 0
                            )}
                          </td>
                          {showBonusAndPlayoffColumns && (
                            <>
                              <td className="desktop-only">
                                {formatDollarAmount(earnings.bonusPayout || 0)}
                              </td>
                              <td className="desktop-only">
                                {formatDollarAmount(totalRegularSeasonEarnings)}
                              </td>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <td>{pointsBack}</td>
                          <td className="desktop-only">
                            {formatDollarAmount(earnings.playoffEarnings || 0)}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </StyledTable>
          </div>
        </div>
      );
    },
    [
      calculateDisplayWeeks,
      currentNFLWeek,
      fontSize,
      weekViewMode,
      theme,
      getColorForRank,
      formatDollarAmount,
      parsedWeeklyRankings,
      showRegularSeasonWeeks,
    ]
  );

  // Memoized legend component
  const renderLegend = useCallback(
    () => (
      <div className="mt-3 text-center">
        <h6>Legend:</h6>
        <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center">
          {legendItems.map((item) => (
            <div key={item.rank} className="d-flex align-items-center mx-2">
              <div
                className={`bg-${item.bootstrapClass}`}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                  border: "1px solid black",
                }}
              />
              <span>{item.rank}</span>
            </div>
          ))}
        </div>
      </div>
    ),
    [legendItems]
  );

  // SSE setup effect
  useEffect(() => {
    const isActive = { current: true };
    let retryCount = 0;

    const setupSSE = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        SSEManager.setAccessToken(accessToken);
        await fetchSeasonData();
        SSEManager.addListener(SSE_ENDPOINT, handleSSEMessage);
        retryCount = 0;
      } catch (err) {
        console.error("Error setting up SSE or fetching initial data:", err);
        setError(`Error: ${err.message}`);

        if (isActive.current && retryCount < MAX_RETRIES) {
          retryCount++;
          setTimeout(setupSSE, RETRY_DELAY);
        }
      }
    };

    setupSSE();

    return () => {
      isActive.current = false;
      SSEManager.removeListener(SSE_ENDPOINT, handleSSEMessage);
    };
  }, [fetchSeasonData, getAccessTokenSilently, handleSSEMessage]);

  // Loading state
  if (loading || isProcessing) {
    return (
      <Container fluid>
        <div
          className="text-center d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="mt-3 text-muted">Loading standings data...</div>
        </div>
      </Container>
    );
  }

  console.log("Playoff data received:", seasonData.playoffs);

  // Main render
  return (
    <Container fluid>
      <div className="py-3">
        <h4 style={{ fontSize: headerFontSize }}>2024 TUFF Standings</h4>
      </div>

      {error && (
        <Alert variant="danger" className="my-3">
          {error}
        </Alert>
      )}

      {!error && seasonData.regularSeason.length === 0 && (
        <Alert variant="info" className="my-3">
          No data available for the season.
        </Alert>
      )}

      {!error && seasonData.regularSeason.length > 0 && (
        <div className="standings-content">
          {(() => {
            const showBonusAndPlayoffColumns =
              currentNFLWeek > REGULAR_SEASON_WEEKS;

            return (
              <>
                {currentNFLWeek > REGULAR_SEASON_WEEKS &&
                  seasonData.playoffs.length > 0 && (
                    <section className="mb-4">
                      {renderTable(seasonData.playoffs, "Playoffs", true)}
                    </section>
                  )}

                <section className="mb-4">
                  {renderTable(
                    seasonData.regularSeason,
                    "Regular Season (Final)",
                    false
                  )}
                </section>

                {(!showBonusAndPlayoffColumns || showRegularSeasonWeeks) && (
                  <section className="d-flex justify-content-center">
                    {renderLegend()}
                  </section>
                )}
              </>
            );
          })()}
        </div>
      )}
    </Container>
  );
};

export default React.memo(TUFFStandingsPage);
