import { useState, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getTradeCount } from "../services/trades.service";
import { getTeamLimits } from "../services/roster.service";

export const useTradeData = (selectedTeam, selectedWeek) => {
  const { getAccessTokenSilently } = useAuth0();
  const [regularTradeCount, setRegularTradeCount] = useState(0);
  const [regularTradeLimit, setRegularTradeLimit] = useState(0);
  const [irTradeCount, setIRTradeCount] = useState(0);
  const [irTradeLimit, setIRTradeLimit] = useState(0);
  const [tradeError, setTradeError] = useState(null);

  const fetchTradeCounts = useCallback(async () => {
    if (!selectedTeam || !selectedWeek) return;

    try {
      const accessToken = await getAccessTokenSilently();
      
      const [regularCountResponse, irCountResponse, teamLimitsResponse] = await Promise.all([
        getTradeCount(accessToken, selectedTeam, "Week 2", selectedWeek, "regular"),
        getTradeCount(accessToken, selectedTeam, "Week 2", selectedWeek, "ir"),
        getTeamLimits(accessToken, selectedTeam, selectedWeek)  // Pass selectedWeek here
      ]);
      // ... rest of the code

      console.log('Regular Count Response:', regularCountResponse);
      console.log('IR Count Response:', irCountResponse);
      console.log('Team Limits Response:', teamLimitsResponse);

      if (regularCountResponse.error || irCountResponse.error || teamLimitsResponse.error) {
        setTradeError("There was an issue fetching trade data. Some data may be unavailable.");
        return;
      }

      // Set counts from the trade count responses
      setRegularTradeCount(regularCountResponse.data);
      setIRTradeCount(irCountResponse.data);

      // Set limits from the team limits response
      setRegularTradeLimit(teamLimitsResponse.data.tradeLimits.regular);
      setIRTradeLimit(teamLimitsResponse.data.tradeLimits.ir);

      setTradeError(null);
    } catch (error) {
      console.error("Error fetching trade data:", error);
      setTradeError("Failed to load trade information");
    }
  }, [selectedTeam, selectedWeek, getAccessTokenSilently]);

  return {
    regularTradeCount,
    regularTradeLimit,
    irTradeCount,
    irTradeLimit,
    tradeError,
    fetchTradeCounts
  };
};